import React, { useState } from "react";

import styles from "../Styles/StudyProtection.module.css";
import rollInStyles from "../Styles/RollInAnimation.module.css";

export default function StudyProtection() {
  const [isShownProfs, setIsShownProfs] = useState(false);

  function toggleProfessions() {
    setIsShownProfs((prev) => !prev);
  }
  return (
    <>
      <div className={rollInStyles.rollInElement}>
        <section>
          <p>
            
            Вінницький учбово-виробничий центр “Сигнал” проводить{" "}
            <strong>навчання посадових осіб</strong>,{" "}
            <strong>спеціалістів та працівників підприємств та організацій</strong>, незалежно
            від форм власності і виду діяльності, з питань охорони праці за дозволом Управління Державної служби з питань праці.
          </p>
          <p>
            Навчання посадових осіб, головних спеціалістів підприємств та
            інженерно-технічних працівників, <strong>у тому числі і позачергове</strong>
            , проводиться з таких питань:{" "}
            <button
              onClick={toggleProfessions}
              className={styles.toggleListButton}
              aria-expanded={isShownProfs}
            >
              {isShownProfs ? "Сховати" : "Список... ↓"}
            </button>
          </p>
          <div
            className={
              isShownProfs
                ? `${styles.professionListContainer} ${styles.visible}`
                : styles.professionListContainer
            }
          >
            <ul className={styles.professionList}>
              <li>
                Законодавчих, нормативних та підзаконних актів з питань охорони
                праці, пожежної безпеки, санітарно - гігієнічного забезпечення,
                надання першої долікарняної допомоги потерпілим при нещасних
                випадках (Законодавства України з охорони праці);
              </li>
              <li>
                Правил будови та безпечної експлуатації ліфтів – НПАОП
                0.00-1.02-08;
              </li>
              <li>
                Правил охорони праці під час виконання робіт на висоті – НПАОП
                0.00-1.15-07 ;
              </li>
              <li>
                Правила техніки безпеки і виробничої санітарії під час обробки природного каменю - НПАОП 26.7-1.01-85
              </li>
              <li>
                Правил охорони праці під час розробки родовищ корисних копалин
                відкритим способом – НПАОП 0.00-1.24-10 ;
              </li>
              <li>
                Правил охорони праці на автомобільному транспорті – НПАОП
                0.00-1.62-12 ;
              </li>
              <li>
                Правил безпеки при виробництві та споживанні продуктів
                розділення повітря – НПАОП 0.00-1.65-88 ;
              </li>
              <li>
                Правил охорони праці під час роботи з інструментом та пристроями
                – НПАОП 0.00-1.71-13 ;
              </li>
              <li>
                Правила охорони праці під час вантажно-розвантажувальних робіт -
                НПАОП 0.00-1.75-15 ;
              </li>
              <li>
                Правил безпеки систем газопостачання (ПБСГ) – НПАОП 0.00-1.76-15
                ;
              </li>
              <li>
                Правил охорони праці під час зварювання металів - НПАОП
                28.52-1.31-13 ;
              </li>
              <li>
                Правил охорони праці під час експлуатації вантажопідіймальних
                кранів, підіймальних пристроїв і відповідного обладнання – НПАОП
                0.00-1.80-18 ;
              </li>
              <li>
                Правил технічної експлуатації електроустановок споживачів
                (ПТЕЕС) ;
              </li>
              <li>
                Правил техніки безпеки при експлуатації систем водопостачання та водовідведення населених місць - НПАОП 41.0-1.01-79 ;
              </li>
              <li>
                Правил охорони праці для працівників лісового господарства та лісової промисловості - НПАОП 02.0-1.04-05 ;
              </li>
              <li>Правил пожежної безпеки та ПТМ ;</li>
              <li>
                Правил безпечної експлуатації електроустановок споживачів (ІІ-V
                гр. доп.)– НПАОП 40.1-1.21-98 ;
              </li>

            </ul>
            <ul className={styles.professionList}>
            <li>
                (ДБН) Система стандартів безпеки праці. Охорона праці і
                промислова безпека у будівництві – НПАОП 45.2-7.02-12 ;
              </li>
              <li>
                Мінімальних вимог з охорони праці на тимчасових або мобільних
                будівельних майданчиках - НПАОП 45.2-7.03-17 ;
              </li>
              <li>Правил охорони праці в деревообробній промисловості НПАОП 20.0-1.02-05;</li>
              <li>
                Правил охорони праці під час експлуатації обладнання, що працює
                під тиском – НПАОП 0.00-1.81-18 ;
              </li>
              <li>
                Правил охорони праці під час технічного обслуговування та ремонту машин і обладнання сільськогосподарського виробництва - НПАОП 01.41-1.01-01 ;
              </li>
              <li>
                Типової інструкції з організації безпечного ведення
                газонебезпечних робіт – НПАОП 0.00-5.11-85 ;
              </li>
              <li>
                Загальних правил вибухобезпеки для вибухопожежонебезпечних хімічних, нафтохімічних і нафтопереробних виробництв - НПАОП 0.00-1.41-88 ;
              </li>
              <li>
                Правил охорони праці у сільськогосподарському виробництві НПАОП - НПАОП 01.0-1.02-18 ;
              </li>
              <li>
                Правил техніки безпеки при роботі з водним аміаком (аміачною
                водою) – НПАОП 01.41-1.07-63 ;
              </li>
              <li>
                Правил безпечного застосування рідкого аміаку в сільському
                господарстві – НПАОП 01.41-1.08-82 ;
              </li>
              <li>
                Правил охорони праці для працівників, зайнятих на роботах зі
                зберігання та переробки зерна – НПАОП 15.0-1.01-17 ;
              </li>
              <li>
                Правил охорони праці для працівників виробництва солоду, пива та безалкогольних напоїв - НПАОП 15.9-1.28-17 ;
              </li>
              <li>
                Правил охорони праці під час експлуатації навантажувачів НПАОП 0.00-1.83-18 ;
              </li>
              <li>
                  Правил охорони праці для працівників м'ясопереробних цехів - НПАОП 15.1-1.06-99 ;
              </li>
              <li>
                  Правил охорони праці для працівників підприємств по переробці молока - НПАОП 15.5-1.05-99 ;
              </li>
              <li>
                Правил охорони праці для працівників зайнятих у цукровому
                виробництві НПАОП 15.8-1.29-18 ;
              </li>
              <li>
                Правил охорони праці в деревообробній промисловості – НПАОП
                20.0-1.02-05 ;
              </li>
            </ul>
          </div>
          <p className="mx-3 mt-2 rounded-2xl bg-[#fece00] p-3 text-justify shadow-md">
            До навчального процесу та перевірки знань залучаються досвідчені
            викладачі та представники державних органів управління та нагляду за
            охороною праці.
          </p>
        </section>
        <section>
          <p className="mt-[100px]">
            Також пропонуємо{" "}
            <strong>
              {" "}
              переатестацію та навчання{" "}
            </strong>{" "}
            з питань охорони праці працівникам (робітникам) таких професій:
          </p>
          <div
            className={`${styles.professionListContainer} ${styles.visible}`}
          >
            <ul className={styles.professionList}>
              <li>Оператор котелень</li>

              <li>Електромонтер з ремонту та обслуг. електрообладнання</li>
              <li>Електрогазозварник</li>
              <li>Верстатник деревообробних верстатів</li>
              <li>Машиніст крана</li>
            </ul>
            <ul className={styles.professionList}>
              <li>Машиніст екскаватора одноковшового</li>
              <li>Стропальник</li>
              <li>Водій навантажувача (-авто, -електро)</li>

              <li>Оператор АЗС</li>
              <li>Ліфтер</li>
              <li>Апаратник оброблення зерна</li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}
